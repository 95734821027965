import { Paper } from '@mui/material'
import { useRouter } from 'next/router'
import { RelatedProductGroups } from '../Amasty/RelatedProductGroups'
import { StandardButton } from '../Button/ButtonStyles'

type BestSellersProps = {
  className?: string
  noHorizontalMargins?: boolean
  homePageComponent?: boolean
}
export function BestSellers(props: BestSellersProps) {
  const { className, noHorizontalMargins, homePageComponent } = props
  const router = useRouter()
  const handleClick = async (href: string) => {
    await router.push(href)
  }

  return (
    <div
      className={`flex w-full items-center justify-center ${
        homePageComponent ? 'mb-0 bg-pure-white' : 'mb-[20px]'
      }`}
    >
      <Paper
        elevation={homePageComponent ? 0 : 3}
        className={`${
          noHorizontalMargins ? 'mx-0' : 'mx-[10px] md:mx-[20px]'
        } max-w-maxContentWidth rounded-md ${className}`}
      >
        <RelatedProductGroups
          position='home_bottom'
          classes={{
            list: 'grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 lg:gap-10 grid-cols-2 gap-[10px]',
          }}
        />
        <div className='my-6 flex w-full items-center justify-center'>
          <StandardButton
            variant='contained'
            size='small'
            className='bg-[#2196F3]'
            onClick={() => handleClick('/search')}
          >
            VIEW ALL
          </StandardButton>
        </div>
      </Paper>
    </div>
  )
}
